import { CFC } from "@api/types/cypress";
import { useEffect, useState } from "react";

type Option = {
  value: string;
  label: string;
};

type SelectInputProps = {
  label: string;
  name: string;
  onChange: (value: string) => void;
  options: Option[];
  defaultValue?: string;
  required?: boolean;
  validationMessage?: string;
};

const SelectInput: CFC<SelectInputProps> = ({
  label,
  name,
  onChange,
  options,
  defaultValue,
  required,
  validationMessage,
  cy,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className="SelectInput">
        <label htmlFor={name}>{label}</label>
        <div className="Select">
          <select
            data-cy={cy}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
              onChange(event.target.value);
            }}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {validationMessage ? (
          <div data-cy={`${cy}-validation`} className="validation">
            {validationMessage}
          </div>
        ) : null}
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .SelectInput {
          }

          label {
            display: block;
            margin: 0 0 4px 0;
            color: #a8b6be;
            font-size: 0.8rem;
          }

          label:after {
            content: ${required ? "'*'" : ""};
            color: red;
          }

          select {
            width: 100%;
            border: 0;
            padding: 7px 10px;
            color: ${value == null || !value.length ? "#a8b6be" : "black"};
          }

          .Select {
            border: 1px solid #a8b6be;
          }

          .validation {
            margin-top: 4px;
            color: red;
            font-size: 0.8rem;
          }
        `}
      </style>
    </>
  );
};

export default SelectInput;
