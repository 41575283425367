import Cart from "@api/types/cart";
import Product from "@api/types/product";
import ProductVariant from "@api/types/product-variant";

export function containsItem(cart: Cart, product: Product, variant: ProductVariant) {
  let contains = false;
  if (cart && product && variant) {
    cart.items.map((item) => {
      if (item.product.id === product.id && item.format === variant.id && item.quantity >= item.maxQuantity) {
        contains = true;
      }
    });
  }
  return contains;
}
