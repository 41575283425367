import { containsItem } from "@api/cart";
import Course from "@api/types/course";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";
import ProductVariant from "@api/types/product-variant";
import React, { useContext } from "react";

import BuyButton from "@components/BuyButton";
import UserContext from "@components/UserContext";

const BuyCourseButton: CFC<{ product: Product; variant: ProductVariant; course: Course; width?: string }> = ({
  product,
  variant,
  course,
  width,
}) => {
  const { cart } = useContext(UserContext);

  return (
    <>
      {course.hasCoursePackages ? (
        course.variantsEnrolledIn.filter((v) => v === variant.id).length === 0 ? (
          !containsItem(cart, product, variant) ? (
            <BuyButton product={product} variant={variant} width={width} />
          ) : (
            <span className="error">Already in cart</span>
          )
        ) : (
          <span className="error">Already enrolled</span>
        )
      ) : (
        <span className="error">Course not ready</span>
      )}
      <style jsx>{`
        .error {
          width: 100%;
          color: red;
          text-align: center;
          padding: 15px 0;
        }
      `}</style>
    </>
  );
};

export default BuyCourseButton;
