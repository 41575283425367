import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";
import ProductVariant from "@api/types/product-variant";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

import Button from "@components/Button";
import { ButtonProps } from "@components/Button/Button";
import UserContext from "@components/UserContext";

type BuyButtonProps = ButtonProps & {
  product: Product;
  variant: ProductVariant;
};

const BuyButton: CFC<BuyButtonProps> = (props) => {
  const router = useRouter();
  const { addProduct } = useContext(UserContext);
  const { product, variant, cy } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  return (
    <>
      <Button
        cy={cy}
        {...props}
        disabled={buttonDisabled}
        onClick={async () => {
          setButtonDisabled(true);

          // This is needed to generate completion records for BSF-1 and add variant to the product name
          // TODO: Need to find a better way to do instead of hard coding it
          const attributes = product.mpn === "BSF-1" ? [{ name: "prod_state", value: variant.id }] : null;

          await addProduct({
            productId: product.id,
            variantId: variant.id,
            quantity: 1,
            setAttribute: attributes,
          }).then(() => setButtonDisabled(false));

          await router.push("/cart");
        }}>
        Buy Now
      </Button>
    </>
  );
};

export default BuyButton;
