import { CFC } from "@api/types/cypress";
import ProductVariant from "@api/types/product-variant";

import SelectInput from "@components/SelectInput";

const ProductVariantSelector: CFC<{ variants: ProductVariant[]; onChange: (v: ProductVariant) => void }> = ({
  variants,
  onChange,
}) => {
  return (
    <SelectInput
      label="Variants"
      name="variants"
      onChange={(value) => {
        const variant = variants.find((v) => v.id === value);
        onChange(variant);
      }}
      options={variants.map((v: ProductVariant) => {
        return { label: v.title, value: v.id };
      })}
    />
  );
};

export default ProductVariantSelector;
